<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';
  import { setFavoriteMovie, removeFavoriteMovie } from '~/services/modules/movies.service';
  import { useQueryClient } from '@tanstack/vue-query';

  interface IProps {
    slug: string;
    id: number;
    queryKey?: string;
    size?: 'small' | 'normal' | 'large';
  }
  const props = withDefaults(defineProps<IProps>(), {
    size: 'large',
  });

  const { updateMoviesFavoriteParam } = useMovieCacheUpdater();
  const isFavorite = defineModel<boolean>();

  const { state } = storeToRefs(useAuthStore());
  const { onReveal } = useAuth();
  const { isPending, mutate: addToFavorite } = setFavoriteMovie();
  const { isPending: isRemoving, mutate: removeFromFavorite } = removeFavoriteMovie(props.id);
  const queryClient = useQueryClient();

  const favoriteChangeCallback = (newValue: boolean) => {
    updateMoviesFavoriteParam(props.id, newValue);
    isFavorite.value = newValue;
  };

  const handleAction = () => {
    if (!state.value.loggedIn) {
      onReveal();
      return;
    }

    if (!isFavorite.value) {
      addToFavorite(
        { films: props.id },
        {
          onSuccess() {
            favoriteChangeCallback(true);
          },
        },
      );
    } else {
      removeFromFavorite(
        { id: props.id },
        {
          onSuccess() {
            favoriteChangeCallback(false);
          },
        },
      );
    }
  };
</script>

<template>
  <v-button
    class="movie-favorite-button"
    :appearance="isFavorite ? 'fill' : 'outline'"
    :color="isFavorite ? 'white' : 'gradient'"
    icon="bookmark"
    media="normal"
    :size="props.size"
    rounded
    :loading="isPending || isRemoving"
    @click="handleAction"
  />
</template>
